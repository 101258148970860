<template>
  <div class="auth-wrapper auth-v2">
    <b-overlay
      id="overlay-background"
      :show="pageIsLoading"
      variant="light"
      opacity="0.85"
      blur="5px"
      rounded="sm"
    >
      <template #overlay>
        <b-card

          class="text-center"
        >

          <h2>
            <b-spinner

              class="mr-1"
              label="Loading"
            />
            {{ overlayMsg }}
          </h2>

        </b-card>
      </template>

      <b-row class="auth-inner m-0">

        <!-- Brand logo-->
        <b-link
          href="https://fastinvoice.me"
          class="brand-logo d-flex align-items-center"
        >
          <span>
            <b-img
              :src="appLogoImage"
              alt="logo"
              style="width:80px;"
            />
          </span>
          <h2 class="brand-text text-primary ml-1">
            {{ appName }}
          </h2>
        </b-link>
        <!-- /Brand logo-->

        <!-- Left Text-->
        <b-col
          lg="7"
          class="d-none d-lg-flex align-items-center p-5"
        >
          <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
            <b-img
              :src="imgUrl"
              fluid
              alt="Welcome"
            />
          </div>
        </b-col>
        <!-- /Left Text-->

        <!-- Reset password-->
        <b-col
          lg="5"
          class="d-flex align-items-center  auth-bg px-2 p-lg-5"
        >
          <b-col
            sm="8"
            md="6"
            lg="12"
            class="px-xl-2 mx-auto "
          >
            <b-card-title
              title-tag="h1"
              class="font-weight-bolder mb-1"
              style="font-size:3rem;"
            >
              Welcome!
            </b-card-title>
            <b-card-text
              style="margin-bottom: 0;"
              class="font-weight-bolder font-weight-bold"
            >
              We've sent you a verification email. Please verify your email address to continue.
            </b-card-text>
            <b-card-text class="font-weight-bold">
              <span>If you did not receive a verification link to your e-mail address, you can resend it using the button below </span>
            </b-card-text>
            <b-button
              class="mt-3"
              size="lg"
              variant="primary"
              @click="resendEmailVerificationLink"
            >
              Resend Verification
            </b-button>
            <br>
            <b-button
              class="mt-3"
              size="lg"
              variant="secondary"
              @click="goBackToLogin"
            >
              Return to Login
            </b-button>
          <!-- form -->

          </b-col>
        </b-col>
      <!-- /Reset password-->
      </b-row>
    </b-overlay>

  </div>
</template>

<script>
/* eslint-disable global-require */
import { $themeConfig } from '@themeConfig'

import {
  BSpinner, BCard, BRow, BCol, BCardTitle, BCardText, BLink, BImg, BButton, BOverlay,
} from 'bootstrap-vue'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { onUnmounted } from '@vue/composition-api'
import accountStoreModule from '../accountStoreModule'

export default {
  components: {
    BSpinner,
    BButton,
    BCard,
    BRow,
    BCol,
    BCardTitle,
    BCardText,
    BImg,
    BLink,
    BOverlay,
  },
  setup() {
    // App Name
    const { appName, appLogoImage } = $themeConfig.app

    const APP_STORE_MODULE_NAME = 'app-account'

    // Register module
    if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, accountStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME)) store.unregisterModule(APP_STORE_MODULE_NAME)
    })

    return {
      appName,
      appLogoImage,
    }
  },
  data() {
    return {
      // eslint-disable-next-line global-require
      sideImg: require('@/assets/images/pages/register-v2.svg'),
      overlayMsg: 'Loading...',
      pageIsLoading: false,
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/reset-password-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    goBackToLogin() {
      // Clear local storage
      localStorage.clear()
      sessionStorage.clear()
      window.location.reload()
    },
    resendEmailVerificationLink() {
      this.pageIsLoading = true
      this.overlayMsg = 'Verification link sending...'
      store
        .dispatch('app-account/resendEmailVerificationLink')
        // eslint-disable-next-line no-unused-vars
        .then(response => {
          this.pageIsLoading = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'A new verification link has been sent to your e-mail address. Please check your inbox.',
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
        })
        // eslint-disable-next-line no-unused-vars
        .catch(error => {
          this.pageIsLoading = false
          if (error.response) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error.response.data.message,
                icon: 'XIcon',
                variant: 'danger',
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Fail',
                icon: 'XIcon',
                variant: 'danger',
              },
            })
          }
        })
    },
  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
